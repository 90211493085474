import React from 'react';
import tera from '../assiets/TERRA_logo_200528.png';
import EPIQUE from '../assiets/IMG_20240428_203541.jpg';
import telma from '../assiets/telma.jpg';

const MaintenancePage = () => {
    const role = localStorage.getItem('role');
    return (
        <div className='container-fluid d-flex justify-content-center align-item-center' style={{ height: '100vh' }}>
            <div className="bg-body rounded" style={{ width: '600px', height: '90%', margin: '20px' }}>
                <div className="row" >
                    <div className="col-6 offset-1" style={{ height: '130px', width: '500px', marginTop: '220px' }}>
                        <img src={EPIQUE} alt="EPIQUE-LOGO" style={{ height: '100%', width: '100%' }} />
                    </div>
                </div>
                <div className="row">
                    <div className="col-4 offset-4" style={{ height: '100px', width: '300px', marginTop: '50px' }}>
                        <img src={role == 'tera' ? tera : telma} alt={`${role}-logo`} style={{ height: '100%', width: '100%' }} />
                    </div>
                </div>
                <div className="row" style={{ position: 'absolute', bottom: '100px' }}>
                    <div className="col-7 offset-1">
                        <p className="fs-4 fw-bold text-center d-flex flex-row justify-content-center">MAINTENANCE</p>
                        <p className='text-center'>
                            Nous nous excusons pour tout inconvénient que cela pourrait vous causer et vous remercions pour votre patience.
                            <br />
                            Nous serons de retour sous peu.
                        </p>

                    </div>
                </div>
            </div>
        </div>
    );
};

export default MaintenancePage;