import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import { Table } from 'react-bootstrap';
import { DataTerra } from '../provider/Data';
import { useQuery } from 'react-query';
import ApiProvider from '../provider/BookProvider';
import Loading from '../pages/Loading';
import terraProvider from '../provider/TeraProvider';
import ShowOneBook from './ShowOneBook';

const BookStat = () => {
    const [search, setSearch] = useState('');
    const [initialFetch, setInitialFetch] = useState(true);
    const [range, setRange] = useState(14);
    const [clicked, setClicked] = useState(false);
    const [book, setBook] = useState();
    const [all, setAll] = useState(true);
    const [bookID, setBookID] = useState();
    const months = ['Janvier', 'Fevrier', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Aout', 'Septembre', 'Octobre', 'Novembre', 'Decembre'];
    const date = new Date();
    const currentMonth = date.getMonth();
    const currentYear = date.getFullYear();
    const [selectedMonth, setSelectedMonth] = useState(-1);
    const [selectedYear, setSelectedYear] = useState(currentYear);
    const { data: viewrs, isLoading: viewsLoading, isError: viewrsError, refetch: refetchViewrs } = useQuery(
        ["fetchViews", bookID, selectedMonth, selectedYear],
        () => terraProvider.fetchViewrs(bookID, selectedMonth, selectedYear),
        { enabled: false }
    );
    const { data: pdfs, isLoading: pdfLoading, refetch: refetchPdfs } = useQuery(
        ["fetchAllBook", selectedMonth, selectedYear],
        () => ApiProvider.fetchAllBooks(selectedMonth, selectedYear),
        {
            enabled: initialFetch,
            onSuccess: () => setInitialFetch(false)
        }
    );
    const onExectut = async () => {
        await refetchPdfs();
    }
    const setValueIfAll = () => {
        !all && setSelectedMonth(currentMonth);
    }
    return (
        <div className='container-fluid'>
            {
                <div className="row">
                    <div className='col-3 offset-1'>
                        <h2>Statistique books</h2>
                    </div>
                    <div className="col-4 offset-3  d-flex align-items-center justify-content-around">
                        <button className={all ? "btn btn-primary border" : "btn btn-light border"} onClick={() => {
                            setAll(!all);
                            setValueIfAll();
                        }}>Globale</button>
                        <div className="border" style={{ height: '10px', width: '1px' }}></div>
                        <div className=" d-flex align-items-center">
                            <select className="form-select bg-light" defaultValue={months[6]} disabled={all ? true : false} onChange={(e) => setSelectedMonth(e.target.selectedIndex)}>
                                {months.map((month, index) => (
                                    <option key={index} value={month}>{month}</option>
                                ))}
                            </select>
                        </div>
                        <div className="d-flex align-items-center">
                            <input className="form-control bg-light" disabled={all ? true : false} type="number" id="number" min="0" max="3000"
                                defaultValue='2024' style={{ height: '35px', borderRadius: '15' }} onChange={e => setSelectedYear(e.target.value)} />
                        </div>
                        <div className="d-flex align-items-center">
                            <button type='button' className='btn btn-primary' onClick={onExectut}>Executer</button>
                        </div>
                    </div>
                    {
                        clicked &&
                        <ShowOneBook book={book} selectedYear='2024' month={months[6]} viewrs={viewrs} viewrsError={viewrsError} setClicked={setClicked} all={all} />
                    }
                    <Form className='py-3'>
                        <Form.Control onChange={e => setSearch(e.target.value)} placeholder='Filtrage par recherche' />
                        <Form.Range max={DataTerra.length} min='1' defaultValue={range} onChange={e => setRange(e.target.value)} />
                    </Form>
                    <div style={{ maxHeight: '70vh', overflowY: 'auto' }} className="table-responsive">
                        {
                            pdfLoading ? <Loading /> :
                                <Table striped bordered hover >
                                    <thead>
                                        <th>Titre</th>
                                        <th>Auteur</th>
                                        <th>Genre</th>
                                        <th>Lectures</th>
                                    </thead>
                                    <tbody >
                                        {
                                            (pdfs && pdfs.data) && pdfs.data
                                                .filter(data => {
                                                    return search.toLowerCase() === '' ? data :
                                                        (data.title.toLowerCase().includes(search) || data.auteur.toLowerCase().includes(search) || data.genre.toLowerCase().includes(search))
                                                })
                                                .sort((a, b) => b.view_history_count - a.view_history_count)
                                                .slice(0, range)
                                                .map((data, index) => (
                                                    <tr key={index} onClick={async () => {
                                                        setBookID(data.id);
                                                        await new Promise(resolve => setTimeout(resolve, 0));
                                                        setBook(data);
                                                        setClicked(true);
                                                        await refetchViewrs();
                                                    }}>
                                                        <td>{data.title}</td>
                                                        <td>{data.auteur}</td>
                                                        <td>{data.genre}</td>
                                                        <td>{data.view_history_count && data.view_history_count}</td>
                                                    </tr>
                                                ))
                                        }
                                    </tbody>
                                </Table>}
                    </div>
                </div>
            }
        </div>
    );
};

export default BookStat;