import React, { useState } from "react";
import UploadPDF from "../assiets/uploadPDF.png";
import ApiProvider from "../provider/BookProvider";
import { useNavigate } from "react-router-dom";

const UploadZone = () => {
    const [selectedFile, setSelectedFile] = useState(null);
    const [loading, setLoading] = useState(false);
    const navigate = new useNavigate();
    const [isSuccess, setIsSuccess] = useState(false);

    const handleFileChange = (e) => {
        setSelectedFile(e.target.files[0]);
    };

    const [formData, setFormData] = useState({
        title: "",
        auteur: "",
        chapter_number: 0,
        genre: "",
        langue: "",
        about: "",
    });

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.id]: e.target.value,
        });
    };

    const navigation = () => {
        navigate('/product');
        setIsSuccess(false);
    }
    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        if (selectedFile) {
            try {
                await ApiProvider.createBook(formData, selectedFile);
                setIsSuccess(true);
                setLoading(false);

            } catch (error) {
                console.error("Failed to create book:", error);
                setLoading(false);
            }
        } else {
            setLoading(false);
            console.log("No file selected");
            alert("No file selected");
        }
    };

    return (
        <div className="container-fluid d-flex flex-column justify-content-between align-items-center" style={{ height: '90%', position: 'relative' }}>
            {
                isSuccess ?
                    <div className="row rounded" style={{ height: '100%', width: '70%', position: 'absolute' }} id="popUPparent">
                        <div className='container bg-body shadow-sm rounded d-flex justify-content-between align-items-center' id="popUP">
                            <div className="col-8 offset-2 d-flex flex-column justify-content-between align-items-center">
                                <h4 className="fs-6 fw-bold">Book created successfully!</h4>
                                <button onClick={navigation} className="btn btn-success">Ok</button>
                            </div>
                        </div>
                    </div>
                    : <></>}
            <form
                onSubmit={handleSubmit}
                className="container rounded bg-body "
                style={{ height: "100%", width: "70%" }}
            >
                <div className="row " style={{ height: "90%" }}>
                    <div className="col-7 offset-1 d-flex align-items-center justify-content-center">
                        <div
                            className="container-fluid d-flex flex-column align-items-center justify-content-around "
                            style={{ height: "80%" }}
                        >
                            <div className="row w-100">
                                <div className="col-3">
                                    <label htmlFor="title" className="col-form-label">
                                        Titre*
                                    </label>
                                </div>
                                <div className="col-7">
                                    <input
                                        type="text"
                                        id="title"
                                        className="form-control"
                                        value={formData.title}
                                        onChange={handleChange}
                                        aria-describedby="passwordHelpInline"
                                        required
                                    />
                                </div>
                            </div>
                            <div className="row w-100">
                                <div className="col-3">
                                    <label htmlFor="auteur" className="col-form-label">
                                        Auteur*
                                    </label>
                                </div>
                                <div className="col-7">
                                    <input
                                        type="text"
                                        id="auteur"
                                        className="form-control"
                                        value={formData.auteur}
                                        onChange={handleChange}
                                        aria-describedby="passwordHelpInline"
                                        required
                                    />
                                </div>
                            </div>
                            <div className="row w-100">
                                <div className="col-3">
                                    <label htmlFor="chapterNumber" className="col-form-label">
                                        Numbre de chapitre
                                    </label>
                                </div>
                                <div className="col-7">
                                    <input
                                        type="number"
                                        id="chapter_number"
                                        className="form-control"
                                        value={formData.chapter_number}
                                        onChange={handleChange}
                                        aria-describedby=""

                                    />
                                </div>
                            </div>

                            <div className="row w-100">
                                <div className="col-3">
                                    <label htmlFor="genre" className="col-form-label">
                                        Genre*
                                    </label>
                                </div>
                                <div className="col-7">
                                    <select
                                        className="form-select"
                                        id="genre"
                                        defaultValue=""
                                        onChange={handleChange}
                                        aria-label="Default select example"
                                        required
                                    >
                                        <option value="">Choisissez*</option>
                                        <option value="Nouvelle">Nouvelle</option>
                                        <option value="Poème">Poème</option>
                                        <option value="Roman">Roman</option>
                                        <option value="Bande dessinée">Bande dessinée</option>
                                        <option value="Développement personnel">Développement personnel</option>
                                        <option value="Management">Management</option>
                                        <option value="Culture générale">Culture générale</option>
                                    </select>
                                </div>
                            </div>
                            <div className="row w-100">
                                <div className="col-3">
                                    <label htmlFor="langue" className="col-form-label">
                                        Langage*
                                    </label>
                                </div>
                                <div className="col-7">
                                    <select
                                        className="form-select"
                                        id="langue"
                                        defaultValue=""
                                        onChange={handleChange}
                                        aria-label="Default select example"
                                        required
                                    >
                                        <option value="">Choisissez*</option>
                                        <option value="Français">Français</option>
                                        <option value="Anglais">Anglais</option>
                                        <option value="Malagasy">Malagasy</option>
                                    </select>
                                </div>
                            </div>
                            <div className="row w-100">
                                <textarea
                                    className="form-control"
                                    placeholder="Description"
                                    value={formData.about}
                                    onChange={handleChange}
                                    id="about"
                                ></textarea>
                            </div>
                        </div>
                    </div>
                    <div className="col-1 d-flex justify-content-center align-items-center">
                        <span
                            className="border"
                            style={{ height: "80%", width: "2px" }}
                        ></span>
                    </div>

                    <div className="col-3 ">
                        <label
                            className="d-flex flex-column align-items-center justify-content-center"
                            htmlFor="files"
                            style={{
                                width: "100%",
                                height: "100%",
                                background: "none",
                                border: "none",
                            }}
                        >
                            <div
                                style={{
                                    height: "40%",
                                    background: "none",
                                    border: "none",
                                    cursor: "pointer",
                                }}
                            >
                                {" "}
                                <img
                                    src={UploadPDF}
                                    alt=""
                                    style={{ width: "200px", height: "100%" }}
                                />
                            </div>
                            <input
                                type="file"
                                accept=".pdf, .epub"
                                id="files"
                                onChange={handleFileChange}
                                style={{ visibility: "hidden" }}
                            />
                            <p className="fw-bold">Upload (PDF)</p>
                            {
                                selectedFile ? <p className="text-success">{selectedFile['name']} séléctionné</p> : <></>
                            }
                        </label>
                    </div>
                </div>
                <div className="row ">
                    <div className="col-12 d-flex justify-content-center align-items-center">
                        <button
                            type="submit"
                            className="btn btn-primary"
                            style={{ width: "200px" }}
                        >
                            {" "}
                            {loading ? "Loading..." : "Submit"}
                        </button>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default UploadZone;