import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { useQuery } from 'react-query';
import terraProvider from '../provider/TeraProvider';

const UserStat = () => {
    const months = ['Janvier', 'Fevrier', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Aout', 'Septembre', 'Octobre', 'Novembre', 'Decembre'];
    const [all, setAll] = useState(false);
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth();
    const currentYear = currentDate.getFullYear();
    const [selectedMonth, setSelectedMonth] = useState(currentMonth);
    const [selectedYear, setSelectedYear] = useState(currentYear);
    const [keyData, setKeyData] = useState([]);
    const [logData, setLogData] = useState([]);
    const [initialFetch, setInitialFetch] = useState(true);
    const { data: logs, isLoading: logLoading, isError: logError, refetch: refetchLogs } =
        useQuery(['fetchLogStats', selectedMonth, selectedYear],
            () => terraProvider.fetchUserLogStat(selectedMonth, selectedYear),
            {
                enabled: initialFetch,
                onSuccess: (data) => {
                    processData(data);
                    setInitialFetch(false);
                }
            }
        );
    const [state, setState] = useState({
        series: [{
            name: 'Oeuvres',
            data: []
        }],
        options: {
            chart: {
                type: 'bar',
                height: 350
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                    columnWidth: '60%',
                    endingShape: 'rounded'
                },
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                show: true,
                width: 2,
                colors: ['transparent']
            },
            xaxis: {
                categories: [0, 1, 2, 3, 4, 5, 6, 7, 8],
            },
            yaxis: {
                tickAmount: 5,
                labels: {
                    formatter: function (val) {
                        return Math.round(val);
                    }
                }
            }
            ,
            fill: {
                opacity: 1
            },
            tooltip: {
                y: {
                    formatter: function (val) {
                        return val;
                    }
                }
            }
        }
    });
    const processData = (logs) => {
        if (logs) {
            setKeyData(Object.keys(logs));
            setLogData(Object.values(logs));
        }
    }
    useEffect(() => {
        if (logs) {
            processData(logs);
        }
    }, [logs]);

    useEffect(() => {
        if (initialFetch) {
            refetchLogs();
        }
    }, [initialFetch, refetchLogs]);
    useEffect(() => {
        setState((prevState) => ({
            ...prevState,
            series: [{ name: 'Actif', data: logData }],
            options: {
                ...prevState.options,
                xaxis: { categories: keyData.map(key => key + ` ${selectedMonth >= 0 ? months[selectedMonth].substring(4, 0) : selectedYear}`) },
                yaxis: { tickAmount: Math.max(...logData) }
            }
        }));
    }, [keyData, logData]);
    const handleChange = async () => {
        await refetchLogs();
    }
    return (
        <div className='container-fluid'>
            <div className="row mb-5">
                <div className='col-3 offset-1'>
                    <h2>Statistique utilisateurs</h2>
                </div>
                <div className="col-4 offset-3  d-flex align-items-center justify-content-around">
                    <button className="btn btn-primary" onClick={() => {
                        if (all) {
                            setAll(false)
                            setSelectedMonth(currentMonth);
                        } else {
                            setAll(true);
                            setSelectedMonth(-2);
                        }
                    }}>{
                            all ? "Annuel" : "Mensuel"
                        }</button>
                    <div className="border" style={{ height: '10px', width: '1px' }}></div>
                    <div className=" d-flex align-items-center">
                        <select className="form-select bg-light" defaultValue={months[selectedMonth]} disabled={all ? true : false}
                            onChange={(e) => setSelectedMonth(e.target.selectedIndex)}>
                            {months.map((month, index) => (
                                <option key={index} value={month}>{month}</option>
                            ))}
                        </select>
                    </div>
                    <div className="d-flex align-items-center">
                        <input className="form-control bg-light" type="number" id="number" min="0" max="3000" defaultValue={selectedYear}
                            style={{ height: '35px', borderRadius: '15' }} onChange={(e) => setSelectedYear(e.target.value)} />
                    </div>
                    <div className="d-flex align-items-center">
                        <button type='button' className='btn btn-primary'
                            onClick={() => {
                                handleChange();
                            }}>
                            Executer</button>
                    </div>
                </div>
            </div>
            <div className="row">
                {
                    logError ? <div>Une erreur s'est produite!</div>
                        : <ReactApexChart options={state.options} series={state.series} type="bar" height={400} />
                }
            </div>
        </div>
    );
};

export default UserStat;