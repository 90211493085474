import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import TERRA from '../assiets/TERRA_logo_200528.png';
import EPIQUE from '../assiets/IMG_20240428_203541.jpg';
import telma from '../assiets/telma.jpg';
import { useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';
import verificationProvider from '../provider/VerificationProvider';

const NavBar = () => {
    const [ifTerra, setifTerra] = useState(false);
    const [delay, setDelay] = useState(false);
    const { data } = useQuery("checkingTerra",
        () => verificationProvider.checkingTerra());
    const token = localStorage.getItem('token');
    const role = localStorage.getItem('role');
    const navigate = useNavigate();
    useEffect(() => {
        if (!token) {
            navigate('/login');
        }
        else if (role) {
            if (role === 'tera') {
                setifTerra(true);
            } else {
                setifTerra(false);
            }
        }
    }, [role, token]);
    useEffect(() => {
        const timer = setTimeout(() => {
            setDelay(true);
        }, 1000);
        return () => clearTimeout(timer);
    }, []);
    return (
        <div className="container-fluid bg-body shadow-sm" id='navbar'>
            <div className="row">
                <div className="col-3">
                    {delay &&
                        <img src={ifTerra ? TERRA : telma} alt="Logo TERRA" style={{ width: '150px', height: '100px' }} />
                    }
                </div>
                <div className="col-5 d-flex align-items-center">
                    <img style={{ width: '250px' }} src={EPIQUE} alt="Logo EPIQUE" />
                </div>
                <div className="col-4">
                    <ul className='list-unstyled d-flex flex-row  justify-content-around h-100'>
                        <NavLink className={(nav) => nav.isActive ? "d-flex align-items-center nav-active" : "d-flex align-items-center"} to="/">
                            <li ><p className="fs-5 fw-bold">{ifTerra ? "Terra" : "Telma"}</p> </li>
                        </NavLink>
                        <NavLink className={(nav) => nav.isActive ? "d-flex align-items-center nav-active" : "d-flex align-items-center"} to="/product">
                            <li ><p className="fs-5 fw-bold">Produits</p> </li>
                        </NavLink>
                        <NavLink className={(nav) => nav.isActive ? "d-flex align-items-center nav-active" : "d-flex align-items-center"} to="/upload-pdf">
                            <li ><p className="fs-5 fw-bold">Téléverser</p></li>
                        </NavLink>
                        {(delay && (data && data.role === 'Epique')) &&
                            <NavLink className={(nav) => nav.isActive ? "d-flex align-items-center nav-active" : "d-flex align-items-center"} to="/statistiques">
                                <li><p className="fs-5 fw-bold">Statistiques</p></li>
                            </NavLink>
                        }
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default NavBar;