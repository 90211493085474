import BASE_URL from "../utils/constant";
const token = localStorage.getItem('token');

class TerraProvider {
    fetchAllUser = async () => {
        try {
            const url = `${BASE_URL}/get-all`;
            const response = await fetch(url,
                {
                    method: "GET",
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    }
                });
            if (!response.ok) {
                throw new Error(`http error, status : ${response.status}`);
            }
            return response.json();
        }
        catch (e) {
            console.error('Error :', e);
        }
    }
    fetchViewrs = async (id, month, year) => {
        try {
            let url = `${BASE_URL}/terabooks/${id}/view`;
            if (month > 0) {
                url += `?month=${month}&year=${year}`;
            }
            const response = await fetch(url,
                {
                    method: "GET",
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    }
                });
            if (!response.ok) {
                throw new Error(`http error, status : ${response.status}`);
            }
            return response.json();
        }
        catch (e) {
            console.error('Error :', e);
        }
    }
    logout = async () => {
        try {
            const url = `${BASE_URL}/logout`;
            const response = await fetch(url,
                {
                    method: 'POST',
                    headers: { 'Authorization': `Bearer ${token}` }
                });
            if (!response.ok) {
                throw new Error("http Error: ", response.status);
            }
        } catch (e) {
            console.error(`Error : ${e}`);
        }
    }
    fetchUserConnected = async () => {
        try {
            const url = `${BASE_URL}/get_user_connected`;
            const response = await fetch(url, {
                method: 'GET',
                headers: { 'Authorization': `Bearer ${token}` }
            })
            if (!response.ok) {
                if (response.status === 500) {
                    throw new Error("http error");
                } else if (response.status === 403) {
                    throw new Error("User not connected");
                }
            }
            return response.json();
        } catch (e) {
            console.error("Error:", e);
        }
    }
    fetchUserLogStat = async (month = null, year = null) => {
        try {
            const role = localStorage.getItem('role');
            let url = `${BASE_URL}/log-stats`;
            if (month && year) {
                url += `/${month + 1}/${year}/${role}`;
            }
            else if (month || year) {
                throw new Error('Both year and month must be provided!');
            }
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    "Content-type": 'application/json',
                    "Authorization": `Bearer ${token}`
                }
            });
            if (!response.ok) {
                throw new Error(`error ${response.status}`);
            }
            return response.json();
        } catch (e) {
            throw new Error("Serveur error: ", e);
        }
    }
    fetchBookStat = async () => {
        try {
            const url = `${BASE_URL}/`;
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    "Content-type": 'application/json',
                    "Authorization": `Bearer ${token}`
                }
            });
            if (!response.ok) {
                throw new Error(`Error ${response.status}`);
            }
            return response.json();
        } catch (e) {
            throw new Error("Server error");
        }
    }
}
const terraProvider = new TerraProvider();
export default terraProvider;