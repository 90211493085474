import React, { useState } from 'react';
import { FaAngleRight } from "react-icons/fa";
import pdfLogo from '../assiets/pdf.png';
import ApiProvider from '../provider/BookProvider';
import { useQuery } from 'react-query';
import terraProvider from '../provider/TeraProvider';
import Loading from '../pages/Loading';

const PDFlst = () => {
    const { data: pdfs, isLoading, isError, refetch: allBookRefetch } = useQuery("fetchAllBook", ApiProvider.fetchAllBooks);
    const [pdfInfo, setPdfInfo] = useState();
    const [pdfInfoLoading, setPdfInfoLoading] = useState(false);
    const [pdfID, setPdfID] = useState();
    const { data: viewers, isLoading: viewersLoading, isError: viewersError, refetch: viewersRefetch }
        = useQuery("fetchViewrs", () => terraProvider.fetchViewrs(pdfID),
            { enabled: !!pdfID });

    const GetPDfInfo = async (book) => {
        setPdfInfoLoading(true);
        setPdfID(book.id);
        setPdfInfo(book);
        await new Promise(resolve => setTimeout(resolve, 0));
        await viewersRefetch();
        setPdfInfoLoading(false);
    }
    const handleDelete = async (id) => {
        const confirm = window.confirm('Êtes-vous sûr de vouloir supprimer cet élément?');
        if (confirm) {
            await deleteBook(id);
        }
    }
    const deleteBook = async (id) => {
        if (id) {
            setPdfInfoLoading(true);
            await ApiProvider.deleteBook(id);
            allBookRefetch();
            alert("Suppression effectuée avec succès!");
            await viewersRefetch();
            setPdfInfo(null);
            setPdfInfoLoading(false);
        }
    }
    if (isError) {
        console.error("error");
    }
    return (
        <div className='container-fluid' style={{ height: '80vh' }}>
            <div className="row">
                <div className="col-7 offset-1  bg-body shadow-sm rounded">
                    <div className="row mb-3" style={{ height: '50px' }} id='headList'>
                        <div className="col-4 d-flex align-items-center justify-content-center"><p className="text-center m-0 fs-5 fw-bold">Titre</p></div>
                        <div className="col-4 d-flex align-items-center justify-content-center"><p className="text-center m-0 fs-5 fw-bold">Auteur</p></div>
                        <div className="col-2 d-flex align-items-center justify-content-center"><p className="text-center m-0 fs-5 fw-bold">Genre</p></div>
                        <div className="col-1 d-flex align-items-center justify-content-center"><p className="text-center m-0 fs-5 fw-bold">Language</p></div>
                    </div>
                    <div className="container-fluid scrollable-list" style={{ height: "70vh", maxHeight: '70vh', overflowY: 'auto' }}>
                        {
                            isError ? <h4>Une erreur s'est produite</h4> :
                                isLoading ?
                                    <Loading /> :
                                    pdfs.data && pdfs.data
                                        .map(
                                            (book, index) => (
                                                <div key={index} className="row mb-1 rounded bg-light" style={{ height: '40px' }} id='pdfList'>
                                                    <div className="col-4  d-flex align-items-center justify-content-center" style={{ overflow: "hidden", maxHeight: "40px" }} >
                                                        <p className="text-center m-0">{book.title}</p>
                                                    </div>
                                                    <div className="col-4  d-flex align-items-center justify-content-center" style={{ overflow: "hidden", maxHeight: "40px" }}>
                                                        <p className="text-center m-0">{book.auteur}</p>
                                                    </div>
                                                    <div className="col-2 d-flex align-items-center justify-content-center">
                                                        <p className="text-center m-0">{book.genre}</p>
                                                    </div>
                                                    <div className="col-1 d-flex align-items-center justify-content-center">
                                                        <p className="text-center m-0">{book.langue}</p>
                                                    </div>
                                                    <div onClick={async () => await GetPDfInfo(book)} style={{ cursor: 'pointer' }} className="col-1 d-flex align-items-center justify-content-center">
                                                        <FaAngleRight />
                                                    </div>
                                                </div>
                                            )
                                        )
                        }
                    </div>
                </div>
                <div className="col-3 " style={{ height: '80vh' }}>
                    <div className='container-fluid bg-body shadow-sm rounded h-100'>
                        <div className="row" style={{ height: '30%' }}>
                            <div className="col-12 d-flex flex-row align-items-center justify-content-between">
                                <img src={pdfLogo} alt="pdf" style={{ height: '60px' }} />
                                {
                                    pdfInfoLoading ? <Loading /> :
                                        !pdfInfo ? <p className="fs-5">Any book selected</p> :
                                            <div className="col mt-5" id="description">
                                                <p className="fs-5 fw-bold text-center">{pdfInfo ? pdfInfo.title : "Title of pdf"}</p>
                                                <p className="fs-6 fw-bold text-center">{pdfInfo ? pdfInfo.auteur : "Author of pdf"}</p>
                                                <p className='text-center'>{pdfInfo && pdfInfo.chapter_number ? `Chapitres: ${pdfInfo.chapter_number}` : ""}</p>
                                                <p className='text-center'>{(viewers && viewers.data) ? `Lectures: ${viewers.data.length}` : ""}</p>
                                            </div>}
                            </div>
                        </div>
                        <div className="row" style={{ height: '30%' }}>
                            <p style={{ overflowY: 'auto', maxHeight: '95%' }} className='scrollable-list fw-bold'>{pdfInfo && pdfInfo.about ? pdfInfo.about : ""}</p>
                        </div>
                        <div className=" row d-flex align-items-center justify-content-center" style={{ height: '31%' }} id='listOfviews'>
                            <ul className='list-unstyled scrollable-list' style={{ maxHeight: '95%', overflowY: 'auto' }}>
                                {
                                    viewersLoading ? <Loading /> :
                                        viewersError ? <div></div> :
                                            viewers && viewers.data ? (
                                                viewers.data
                                                    .map((user, index) => (
                                                        <li key={index} className=' d-flex flex-row justify-content-between bg-light mb-1'>
                                                            <div className="container-fluid d-flex align-items-center" style={{ height: '35px' }}>
                                                                <div className="row">
                                                                    <div className="col h-3 d-flex align-items-center"><p className="fs-5 m-0 text-start">{user.name}</p></div>
                                                                </div>

                                                            </div>
                                                        </li>)
                                                    )) :
                                                <></>
                                }

                            </ul>

                        </div>
                        <div className="row mt-3">
                            {
                                pdfID ?
                                    <div className="col-4 offset-4">
                                        <button onClick={async () => await handleDelete(pdfID)} className="btn btn-danger">Supprimer</button>
                                    </div> : <></>
                            }
                        </div>
                    </div>
                </div>
            </div>

        </div >
    );
};

export default PDFlst;