import React, { useState, useEffect } from 'react';
import { FaUser, FaLock } from "react-icons/fa";
import { useNavigate } from 'react-router-dom';
import BASE_URL from '../utils/constant';
import { Spinner } from 'react-bootstrap';

const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            navigate('/');
        }
    });
    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        const credential = {
            email,
            password
        }
        try {
            const response = await fetch(`${BASE_URL}/login`,
                {
                    method: 'POST',
                    headers: {
                        'Content-type': 'application/json'
                    },
                    body: JSON.stringify(credential)
                }).then(response => {
                    if (!response.ok) {
                        if (response.status === 422) {
                            alert('Email or password invalid');
                            setIsLoading(false);
                        }
                        throw new Error(`http error! Status : ${response.status}`);
                    }
                    return response.json();
                })
                .then(data => {
                    const { token } = data;
                    localStorage.setItem('token', token);
                    localStorage.setItem('role', data.user.role);
                    setIsLoading(false);
                    window.location.replace('/');
                });
        } catch (e) {
            setIsLoading(false);
            console.error("error :", e);
        }
    }

    return (
        <div className="loginpage">
            <div className='login'>
                <form onSubmit={handleSubmit}>
                    <h1>Login</h1>
                    <div className="input-box">
                        <input type="email" value={email} placeholder='Email' onChange={(e) => setEmail(e.target.value)} required />
                        <FaUser className='icon' />
                    </div>
                    <div className="input-box">
                        <input type="password" value={password} placeholder='Password' onChange={(e) => setPassword(e.target.value)} required />
                        <FaLock className='icon' />
                    </div>
                    <button type="submit">
                        {
                            isLoading ? <Spinner /> : "Connecter"
                        }
                    </button>
                </form>
            </div>
        </div>
    );
};

export default Login;