export const DataTerra = [{
    "title": "Honorable",
    "author": "Adelind Debenham",
    "genre": "fiction",
    "publication_date": "9/16/1931",
    "language": "Latvian",
    "page_count": 144
}, {
    "title": "Ms",
    "author": "Sherlock Willatt",
    "genre": "fiction",
    "publication_date": "4/26/1932",
    "language": "Dari",
    "page_count": 47
}, {
    "title": "Ms",
    "author": "Lanni Chasle",
    "genre": "romance",
    "publication_date": "2/16/1973",
    "language": "Arabic",
    "page_count": 1237
}, {
    "title": "Mrs",
    "author": "Juline Derwin",
    "genre": "fiction",
    "publication_date": "8/1/1919",
    "language": "Somali",
    "page_count": 731
}, {
    "title": "Mrs",
    "author": "Piggy Baughan",
    "genre": "romance",
    "publication_date": "8/2/1947",
    "language": "Latvian",
    "page_count": 1146
}, {
    "title": "Ms",
    "author": "Filippa Jahnisch",
    "genre": "non-fiction",
    "publication_date": "10/28/2007",
    "language": "Tok Pisin",
    "page_count": 795
}, {
    "title": "Dr",
    "author": "Birgit Cabedo",
    "genre": "romance",
    "publication_date": "10/30/1960",
    "language": "Lithuanian",
    "page_count": 959
}, {
    "title": "Honorable",
    "author": "Gena Gavagan",
    "genre": "non-fiction",
    "publication_date": "8/5/2022",
    "language": "Latvian",
    "page_count": 1397
}, {
    "title": "Mr",
    "author": "Sterne Stronach",
    "genre": "fiction",
    "publication_date": "6/2/1955",
    "language": "Fijian",
    "page_count": 334
}, {
    "title": "Honorable",
    "author": "Christoffer Andreichik",
    "genre": "non-fiction",
    "publication_date": "7/27/1965",
    "language": "Filipino",
    "page_count": 1087
}, {
    "title": "Rev",
    "author": "Doretta Jasik",
    "genre": "fiction",
    "publication_date": "9/26/1955",
    "language": "Estonian",
    "page_count": 789
}, {
    "title": "Ms",
    "author": "Jermaine Yare",
    "genre": "non-fiction",
    "publication_date": "10/23/1956",
    "language": "Kazakh",
    "page_count": 199
}, {
    "title": "Honorable",
    "author": "Susi Yuryatin",
    "genre": "mystery",
    "publication_date": "2/21/1941",
    "language": "Swahili",
    "page_count": 393
}, {
    "title": "Mr",
    "author": "Eustacia Dutnell",
    "genre": "non-fiction",
    "publication_date": "11/29/1969",
    "language": "Khmer",
    "page_count": 636
}, {
    "title": "Mr",
    "author": "Helga Rundall",
    "genre": "romance",
    "publication_date": "11/10/1983",
    "language": "Lithuanian",
    "page_count": 662
}, {
    "title": "Dr",
    "author": "Colver Hadny",
    "genre": "non-fiction",
    "publication_date": "1/25/1960",
    "language": "Haitian Creole",
    "page_count": 1022
}, {
    "title": "Mr",
    "author": "Sydney Moore",
    "genre": "non-fiction",
    "publication_date": "7/16/1921",
    "language": "Sotho",
    "page_count": 1172
}, {
    "title": "Honorable",
    "author": "Godard Byrcher",
    "genre": "non-fiction",
    "publication_date": "1/25/1958",
    "language": "Malayalam",
    "page_count": 551
}, {
    "title": "Dr",
    "author": "Heidi Hallard",
    "genre": "mystery",
    "publication_date": "6/13/2000",
    "language": "Indonesian",
    "page_count": 568
}, {
    "title": "Mr",
    "author": "Lesya Fulkes",
    "genre": "mystery",
    "publication_date": "2/26/1917",
    "language": "Yiddish",
    "page_count": 421
}, {
    "title": "Dr",
    "author": "Rowland Bambrough",
    "genre": "non-fiction",
    "publication_date": "4/1/1979",
    "language": "Hindi",
    "page_count": 1160
}, {
    "title": "Dr",
    "author": "Kipper Dresse",
    "genre": "romance",
    "publication_date": "8/26/1975",
    "language": "Yiddish",
    "page_count": 1062
}, {
    "title": "Ms",
    "author": "Lorens Iacovelli",
    "genre": "fiction",
    "publication_date": "7/31/1997",
    "language": "Telugu",
    "page_count": 384
}, {
    "title": "Mr",
    "author": "Phip McKeurton",
    "genre": "non-fiction",
    "publication_date": "9/7/1995",
    "language": "Kannada",
    "page_count": 885
}, {
    "title": "Mrs",
    "author": "Marlowe Odde",
    "genre": "mystery",
    "publication_date": "12/9/1944",
    "language": "Kannada",
    "page_count": 71
}, {
    "title": "Mr",
    "author": "Shane Kornacki",
    "genre": "romance",
    "publication_date": "8/20/1961",
    "language": "Tsonga",
    "page_count": 444
}, {
    "title": "Mr",
    "author": "Glennie Jeffryes",
    "genre": "mystery",
    "publication_date": "9/27/1937",
    "language": "Mongolian",
    "page_count": 991
}, {
    "title": "Honorable",
    "author": "Ibrahim Torbet",
    "genre": "non-fiction",
    "publication_date": "4/16/1929",
    "language": "Greek",
    "page_count": 223
}, {
    "title": "Dr",
    "author": "Norah Kittman",
    "genre": "romance",
    "publication_date": "3/29/2011",
    "language": "Portuguese",
    "page_count": 689
}, {
    "title": "Dr",
    "author": "Walton Atkins",
    "genre": "non-fiction",
    "publication_date": "7/24/1980",
    "language": "Ndebele",
    "page_count": 89
}, {
    "title": "Mr",
    "author": "Bridget Spinetti",
    "genre": "romance",
    "publication_date": "5/8/1916",
    "language": "Azeri",
    "page_count": 1057
}, {
    "title": "Honorable",
    "author": "Cass Berceros",
    "genre": "fiction",
    "publication_date": "4/16/2005",
    "language": "Hebrew",
    "page_count": 1121
}, {
    "title": "Mrs",
    "author": "Darcey Keeble",
    "genre": "mystery",
    "publication_date": "11/29/1954",
    "language": "Kurdish",
    "page_count": 583
}, {
    "title": "Dr",
    "author": "Elissa Grooby",
    "genre": "non-fiction",
    "publication_date": "1/10/2021",
    "language": "Malay",
    "page_count": 1158
}, {
    "title": "Rev",
    "author": "Josiah Ferrucci",
    "genre": "mystery",
    "publication_date": "1/25/1916",
    "language": "Swati",
    "page_count": 1237
}, {
    "title": "Mr",
    "author": "Hall Antyshev",
    "genre": "fiction",
    "publication_date": "7/15/1900",
    "language": "Japanese",
    "page_count": 354
}, {
    "title": "Dr",
    "author": "Florette Franchi",
    "genre": "non-fiction",
    "publication_date": "12/25/1956",
    "language": "Latvian",
    "page_count": 981
}, {
    "title": "Rev",
    "author": "Lesley Castaner",
    "genre": "fiction",
    "publication_date": "6/5/1978",
    "language": "Tswana",
    "page_count": 474
}, {
    "title": "Rev",
    "author": "Pegeen Agnew",
    "genre": "romance",
    "publication_date": "9/21/1922",
    "language": "Armenian",
    "page_count": 849
}, {
    "title": "Dr",
    "author": "Ingeberg Carlsen",
    "genre": "non-fiction",
    "publication_date": "9/22/1937",
    "language": "Bengali",
    "page_count": 822
}, {
    "title": "Ms",
    "author": "Genvieve Caruth",
    "genre": "romance",
    "publication_date": "1/21/1917",
    "language": "Zulu",
    "page_count": 27
}, {
    "title": "Honorable",
    "author": "Bliss Donson",
    "genre": "mystery",
    "publication_date": "10/26/2016",
    "language": "Amharic",
    "page_count": 664
}, {
    "title": "Dr",
    "author": "Erskine Wattam",
    "genre": "non-fiction",
    "publication_date": "11/19/1901",
    "language": "Afrikaans",
    "page_count": 890
}, {
    "title": "Mr",
    "author": "Amandie McGirr",
    "genre": "fiction",
    "publication_date": "11/3/1907",
    "language": "Somali",
    "page_count": 750
}, {
    "title": "Dr",
    "author": "Augusto Awde",
    "genre": "romance",
    "publication_date": "3/15/2021",
    "language": "Punjabi",
    "page_count": 716
}, {
    "title": "Honorable",
    "author": "Wolf Oattes",
    "genre": "non-fiction",
    "publication_date": "4/22/1975",
    "language": "Japanese",
    "page_count": 1199
}, {
    "title": "Dr",
    "author": "Ailyn Blazewski",
    "genre": "mystery",
    "publication_date": "2/8/1975",
    "language": "Yiddish",
    "page_count": 1061
}, {
    "title": "Ms",
    "author": "Idelle Barhem",
    "genre": "non-fiction",
    "publication_date": "3/20/1916",
    "language": "Korean",
    "page_count": 421
}, {
    "title": "Dr",
    "author": "Eugen Trillow",
    "genre": "fiction",
    "publication_date": "7/27/1969",
    "language": "Spanish",
    "page_count": 917
}, {
    "title": "Dr",
    "author": "Heddi Hammett",
    "genre": "romance",
    "publication_date": "2/13/1914",
    "language": "Lithuanian",
    "page_count": 693
}, {
    "title": "Mrs",
    "author": "Dayna Ewart",
    "genre": "mystery",
    "publication_date": "7/18/1986",
    "language": "German",
    "page_count": 472
}, {
    "title": "Mr",
    "author": "Niki Fairleigh",
    "genre": "fiction",
    "publication_date": "6/26/1946",
    "language": "Dari",
    "page_count": 835
}, {
    "title": "Rev",
    "author": "Adolphus Harbert",
    "genre": "non-fiction",
    "publication_date": "8/26/1941",
    "language": "Montenegrin",
    "page_count": 194
}, {
    "title": "Mr",
    "author": "Annadiana Wichard",
    "genre": "non-fiction",
    "publication_date": "10/18/1967",
    "language": "Marathi",
    "page_count": 265
}, {
    "title": "Mr",
    "author": "Efren Bedborough",
    "genre": "romance",
    "publication_date": "7/18/1940",
    "language": "Thai",
    "page_count": 166
}, {
    "title": "Mrs",
    "author": "Rolfe Fallon",
    "genre": "mystery",
    "publication_date": "6/29/1906",
    "language": "Filipino",
    "page_count": 400
}, {
    "title": "Ms",
    "author": "Angeli Lilleyman",
    "genre": "fiction",
    "publication_date": "2/18/2002",
    "language": "Hindi",
    "page_count": 934
}, {
    "title": "Dr",
    "author": "Rhona Leroy",
    "genre": "fiction",
    "publication_date": "6/7/1926",
    "language": "Armenian",
    "page_count": 963
}, {
    "title": "Rev",
    "author": "Ilise Eisenberg",
    "genre": "non-fiction",
    "publication_date": "5/15/1932",
    "language": "Croatian",
    "page_count": 540
}, {
    "title": "Mrs",
    "author": "Wilmar Igoe",
    "genre": "non-fiction",
    "publication_date": "3/4/1986",
    "language": "Lao",
    "page_count": 1416
}, {
    "title": "Dr",
    "author": "Sarena Wood",
    "genre": "mystery",
    "publication_date": "9/4/2015",
    "language": "Hindi",
    "page_count": 139
}, {
    "title": "Honorable",
    "author": "Almira Lippett",
    "genre": "romance",
    "publication_date": "12/12/1960",
    "language": "Punjabi",
    "page_count": 589
}, {
    "title": "Mrs",
    "author": "Chloette Flori",
    "genre": "mystery",
    "publication_date": "1/31/1928",
    "language": "Marathi",
    "page_count": 331
}, {
    "title": "Dr",
    "author": "Margie Waddicor",
    "genre": "fiction",
    "publication_date": "11/21/2001",
    "language": "Pashto",
    "page_count": 335
}, {
    "title": "Mr",
    "author": "Sharl Sandaver",
    "genre": "romance",
    "publication_date": "3/20/1910",
    "language": "Papiamento",
    "page_count": 342
}, {
    "title": "Mrs",
    "author": "Ellwood McCrae",
    "genre": "non-fiction",
    "publication_date": "11/3/1998",
    "language": "Tamil",
    "page_count": 1472
}, {
    "title": "Ms",
    "author": "Kerianne Emmins",
    "genre": "mystery",
    "publication_date": "3/27/2011",
    "language": "Filipino",
    "page_count": 1475
}, {
    "title": "Ms",
    "author": "Geri Vernon",
    "genre": "romance",
    "publication_date": "5/29/2017",
    "language": "Hungarian",
    "page_count": 235
}, {
    "title": "Dr",
    "author": "Paulette Bunton",
    "genre": "non-fiction",
    "publication_date": "5/5/1907",
    "language": "Tsonga",
    "page_count": 718
}, {
    "title": "Rev",
    "author": "Trstram Lloyd",
    "genre": "non-fiction",
    "publication_date": "12/21/1906",
    "language": "Georgian",
    "page_count": 1018
}, {
    "title": "Mr",
    "author": "Rafa Wyllt",
    "genre": "romance",
    "publication_date": "3/20/2009",
    "language": "Mongolian",
    "page_count": 1370
}, {
    "title": "Mr",
    "author": "Web Machin",
    "genre": "mystery",
    "publication_date": "1/9/1941",
    "language": "Bulgarian",
    "page_count": 337
}, {
    "title": "Rev",
    "author": "Barbee Blakeston",
    "genre": "non-fiction",
    "publication_date": "10/3/1927",
    "language": "Finnish",
    "page_count": 955
}, {
    "title": "Dr",
    "author": "Candida Papierz",
    "genre": "romance",
    "publication_date": "3/14/2003",
    "language": "Swahili",
    "page_count": 1051
}, {
    "title": "Ms",
    "author": "Boycey Cargill",
    "genre": "mystery",
    "publication_date": "7/24/1983",
    "language": "Bulgarian",
    "page_count": 1351
}, {
    "title": "Mrs",
    "author": "Fern Shelvey",
    "genre": "mystery",
    "publication_date": "10/2/1963",
    "language": "Burmese",
    "page_count": 1109
}, {
    "title": "Mr",
    "author": "Rice L'argent",
    "genre": "romance",
    "publication_date": "3/19/1969",
    "language": "Arabic",
    "page_count": 1207
}, {
    "title": "Mr",
    "author": "Lorinda Bevir",
    "genre": "non-fiction",
    "publication_date": "9/23/2010",
    "language": "Bosnian",
    "page_count": 1451
}, {
    "title": "Rev",
    "author": "Gwennie Gimenez",
    "genre": "fiction",
    "publication_date": "4/26/2013",
    "language": "Persian",
    "page_count": 1195
}, {
    "title": "Dr",
    "author": "Sean Breckenridge",
    "genre": "romance",
    "publication_date": "4/27/1991",
    "language": "Polish",
    "page_count": 609
}, {
    "title": "Mrs",
    "author": "Judy Cashell",
    "genre": "romance",
    "publication_date": "7/14/1906",
    "language": "Tok Pisin",
    "page_count": 411
}, {
    "title": "Rev",
    "author": "Ron Sandaver",
    "genre": "romance",
    "publication_date": "1/11/1977",
    "language": "Armenian",
    "page_count": 285
}, {
    "title": "Mr",
    "author": "Nady Lake",
    "genre": "fiction",
    "publication_date": "11/17/2001",
    "language": "Luxembourgish",
    "page_count": 880
}, {
    "title": "Mr",
    "author": "Gratia Lerwill",
    "genre": "non-fiction",
    "publication_date": "3/14/1916",
    "language": "Armenian",
    "page_count": 778
}, {
    "title": "Dr",
    "author": "Eloisa Cestard",
    "genre": "fiction",
    "publication_date": "7/5/1937",
    "language": "Nepali",
    "page_count": 827
}, {
    "title": "Mrs",
    "author": "Broddy Brodhead",
    "genre": "fiction",
    "publication_date": "8/18/1974",
    "language": "Estonian",
    "page_count": 194
}, {
    "title": "Mr",
    "author": "Justine Hixley",
    "genre": "non-fiction",
    "publication_date": "8/14/1942",
    "language": "Kyrgyz",
    "page_count": 1
}, {
    "title": "Ms",
    "author": "Rosana Bushnell",
    "genre": "fiction",
    "publication_date": "9/12/1943",
    "language": "Haitian Creole",
    "page_count": 1300
}, {
    "title": "Honorable",
    "author": "Bamby MacAnellye",
    "genre": "romance",
    "publication_date": "10/28/1943",
    "language": "Persian",
    "page_count": 891
}, {
    "title": "Rev",
    "author": "Alvis Scarth",
    "genre": "romance",
    "publication_date": "11/10/2001",
    "language": "Hiri Motu",
    "page_count": 1079
}, {
    "title": "Mrs",
    "author": "Keefe Moret",
    "genre": "romance",
    "publication_date": "1/13/1999",
    "language": "Assamese",
    "page_count": 463
}, {
    "title": "Dr",
    "author": "Hadria Benko",
    "genre": "mystery",
    "publication_date": "8/10/1978",
    "language": "Aymara",
    "page_count": 276
}, {
    "title": "Honorable",
    "author": "Shandy Bateman",
    "genre": "romance",
    "publication_date": "12/31/1914",
    "language": "Aymara",
    "page_count": 1314
}, {
    "title": "Honorable",
    "author": "Frederich Drysdale",
    "genre": "non-fiction",
    "publication_date": "9/20/1998",
    "language": "Assamese",
    "page_count": 3
}, {
    "title": "Mrs",
    "author": "Alissa Belcher",
    "genre": "romance",
    "publication_date": "11/18/1967",
    "language": "Japanese",
    "page_count": 391
}, {
    "title": "Rev",
    "author": "Cleveland Le Quesne",
    "genre": "romance",
    "publication_date": "3/19/1993",
    "language": "Luxembourgish",
    "page_count": 1355
}, {
    "title": "Ms",
    "author": "Xaviera Heppenspall",
    "genre": "non-fiction",
    "publication_date": "1/12/1942",
    "language": "Swati",
    "page_count": 300
}, {
    "title": "Ms",
    "author": "Olympe Sante",
    "genre": "mystery",
    "publication_date": "10/8/1906",
    "language": "Nepali",
    "page_count": 957
}, {
    "title": "Dr",
    "author": "Carlota Ceaser",
    "genre": "fiction",
    "publication_date": "12/17/2022",
    "language": "Estonian",
    "page_count": 1302
}, {
    "title": "Ms",
    "author": "Leon Womack",
    "genre": "non-fiction",
    "publication_date": "10/18/1942",
    "language": "Assamese",
    "page_count": 188
}]
