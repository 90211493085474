import React from 'react';
import NavBar from '../components/NavBar';
import PDFlst from '../components/PDFlst';
import Logout from '../components/Logout';

const ProductPage = () => {
    return (
        <div className='container-fluid' style={{ height: '100vh' }}>
            <div className="row mb-5">
                <NavBar />
            </div>
            <div className="row " style={{ height: '85vh' }}>
                <PDFlst />
            </div>
            <div className="row">
                <Logout />
            </div>
        </div>
    );
};

export default ProductPage;