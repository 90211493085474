import React, { useState } from 'react';
import NavBar from '../components/NavBar';
import BookStat from '../components/BookStat';
import UserStat from '../components/UserStat';
import Logout from '../components/Logout';

const Statistique = () => {
    const [isBook, setIsBook] = useState(false);
    return (
        <div className='container-fuild'>
            <div className="row mb-5 h-100">
                <NavBar />
            </div>
            <div className="row bookORuser">
                <button className={isBook ? 'col-2 offset-4 btn-light' : 'col-2 offset-4 bg-body btn-light'} style={{ cursor: "pointer", }} onClick={() => setIsBook(false)} >Utilisateurs</button>
                <button className={isBook ? 'col-2 btn-light bg-body' : 'col-2 btn-light'} style={{ cursor: "pointer", }} onClick={() => setIsBook(true)}>Produits</button>
            </div>
            <div className="row bg-body" style={{ height: "100%" }}>
                {
                    isBook ? <BookStat />
                        : <UserStat />
                }
            </div>
            <Logout />
        </div>
    );
};

export default Statistique;