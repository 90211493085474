import React from 'react';
import { MdOutlineLogout } from "react-icons/md";
import terraProvider from '../provider/TeraProvider';
import { useNavigate } from 'react-router-dom';

const Logout = () => {
    const token = localStorage.getItem('token');
    const navigate = new useNavigate();

    const handleLogout = async () => {
        const confirm = window.confirm("Voulez-vous vraiment vous déconnecté du site ?");
        if (confirm) {
            await logout();
            localStorage.removeItem('token');
            navigate('/login');
        }
    }
    const logout = async () => {
        await terraProvider.logout();
    }
    return (
        <div className='bg-body shadow-sm d-flex align-items-end justify-content-center' id='logout'>
            <p className='fs-5 fw-bold text-center text-danger' onClick={handleLogout}> <MdOutlineLogout /> </p>
        </div>
    );
};

export default Logout;