import React from 'react';
import NavBar from '../components/NavBar';
import UploadZone from '../components/UploadZone';
import Logout from '../components/Logout';

const Upload = () => {
    return (
        <div className='container-fluid' style={{ height: '100vh' }}>
            <div className="row mb-5">
                <NavBar />
            </div>
            <div className="row" style={{ height: '80%' }}>
                <UploadZone />
            </div>
            <div className="row">
                <Logout />
            </div>
        </div>
    );
};

export default Upload;