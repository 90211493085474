import React from 'react';

const ShowOneBook = ({ book, setClicked, selectedYear, month, viewrs, viewrsError, all }) => {
    return (
        <div className='container-fluid statPopUp bg-body' style={{ position: 'absolute', zIndex: '1', width: '100%', height: '100%', right: '0px' }}>
            <button className='btn btn-body' onClick={() => setClicked(false)}>X</button>
            <div className="row">
                <div className='d-flex flex-column align-items-center'>
                    <h3>{book.title}</h3>
                    <h6>{book.author}</h6>
                    <h5>{book.genre}</h5>
                </div>
            </div>
            <div className="row">
                <div className="col-5 offset-1">
                    <p className='text-end'>{`${book.chapter_number > 0 ? `Chaptire : ${book.chapter_number}` : ''}`}</p>
                    <div className="list-scrollable description" style={{ maxHeight: '500px', overflowY: 'auto' }}>
                        <p className='fs-4'>{book.about}</p>
                    </div>
                </div>
                <div className="col-5">
                    <div className="container-fluid">
                        <div className="row">
                            <p>{!all &&
                                `${month} ${selectedYear}`
                            }</p>
                            <p>{`${book.views > 0 ? `Lectures : ${book.views}` : ''}`}</p>
                            <ul className="list-scrollable list-unstyled" style={{ maxHeight: '500px', overflowY: 'auto', top: '20px' }}>
                                {
                                    viewrsError ? <p className="text-center">Une erreur s'est produite!</p> :
                                        (viewrs && viewrs.data) && viewrs.data
                                            .map((view, index) => (
                                                <li key={index} className='d-flex flex-row'>
                                                    <div className="col-4 bg-light mb-1 ">{view.user.name}</div>
                                                    <div className="col-2 bg-light text-center mb-1">x {view.view_count}</div>
                                                </li>
                                            ))
                                }
                            </ul>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
};

export default ShowOneBook;