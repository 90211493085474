import React from 'react';
import NavBar from '../components/NavBar';
import { useQuery } from 'react-query';
import terraProvider from '../provider/TeraProvider';
import Loading from './Loading';
import Logout from '../components/Logout';

const UserPage = () => {
    const { data, isLoading, isError } = useQuery('fetchUser', terraProvider.fetchAllUser);
    if (isLoading) {
        return (
            <div className="container-fluid h-100">
                <div className="row">
                    <NavBar />
                </div>
                <div className="row">
                    <Loading />

                </div>
            </div>
        );
    }
    if (isError) {
        <div className="container-fluid h-100">
            <div className="row">
                <NavBar />
            </div>
            <div className="row">
                <h4>Something went wrong</h4>
            </div>
        </div>
    }
    return (
        <div className='container-fluid'>
            <div className="row ">
                <NavBar />
            </div>
            <div className="scrollable-list row m-5" style={{ maxHeight: '80vh', overflowY: 'auto' }}>
                {data && data.data
                    .map((user) => (
                        <div key={user.id} className="col-4 mb-3 d-flex justify-content-center align-items-center">
                            <div className="bg-body d-flex flex-column justify-content-around align-items-center rounded shadow-sm h-100" id="userCard" style={{ height: '80%', width: '80%' }}>
                                <div className='rounded' style={{ maxHeight: '100px', maxWidth: '100px' }} id="userPdp"><img style={{ height: '100px', width: '100px' }} src={user.cover_url} className='rounded' alt="Profil" /></div>
                                <p className="text-center fs-4 fw-bold">{user.name}</p>
                                <p className="text-center fs-6">{user.email}</p>
                            </div>
                        </div>
                    ))
                }
                <Logout />
            </div>
        </div>
    );
};

export default UserPage;