import { BrowserRouter, Routes, Route } from "react-router-dom";
import ProductPage from "./pages/ProductPage";
import Upload from "./pages/Upload";
import Login from "./pages/LoginPage";
import UserPage from "./pages/UserPage";
import Statistique from "./pages/Statistique";
import MaintenancePage from "./pages/MaintenancePage";

function App() {
  return (
    <BrowserRouter>
      <Routes >
        <Route path="/product" element={<ProductPage />} />
        <Route path="/" element={<UserPage />} />
        <Route path="*" element={<UserPage />} />
        <Route path="/upload-pdf" element={<Upload />} />
        <Route path="/login" element={<Login />} />
        <Route path="/statistiques" element={<Statistique />} />
        <Route path='/maintenance' element={<MaintenancePage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
