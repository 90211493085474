import BASE_URL from "../utils/constant";
const token = localStorage.getItem('token');

class Verification {
    maintenanceActication = async (status) => {
        let url = `${BASE_URL}/maintenanceActivation`;
        const response = await fetch(url,
            {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    'status': status
                })
            }
        );
        return response.json();
    }

    checkingTerra = async () => {
        let url = `${BASE_URL}/check-if-terra`;
        const response = await fetch(url,
            {
                method: "GET",
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            }
        );
        if (!response.ok) {
            throw new Error("HTTP error, status code :", response.status);
        }
        return response.json();
    }
}
const verificationProvider = new Verification();
export default verificationProvider;