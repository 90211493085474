import BASE_URL from '../utils/constant'
const token = localStorage.getItem('token');

class BookProvier {
    fetchAllBooks = async (month, year) => {
        let url = `${BASE_URL}/show_view`;
        if (month > 0) {
            url += `?month=${month}&year=${year}`;
        }
        try {
            const response = await fetch(url,
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    },

                }
            )
            if (!response.ok) {
                throw new Error("http error! Status", response.status);
            }
            return response.json();
        }
        catch (e) {
            console.error("Error :", e);
        }
    }
    fetchOneBook = async (book_id) => {
        const url = `${BASE_URL}/tera/${book_id}`;
        try {
            const response = await fetch(url,
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    }
                }
            )
            if (!response.ok) {
                throw new Error("http error! Status", response.status);
            }
            return response.json();
        }
        catch (e) {
            console.error("Error :", e);
        }
    }
    updateBook = async (book_id) => {
        const url = `${BASE_URL}/terabook/${book_id}`;
        try {
            const response = await fetch(url,
                {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    }
                }
            )
            if (!response.ok) {
                throw new Error("http error! Status", response.status);
            }
            // return response.json();
        }
        catch (e) {
            console.error("Error :", e);
        }
    }
    _createBook = async (infos, selectedFile) => {
        const url = `${BASE_URL}/terabook`;
        const formData = new FormData();
        try {
            formData.append("title", infos.title);
            formData.append("about", infos.about);
            formData.append("langue", infos.langue);
            formData.append("auteur", infos.auteur);
            formData.append("genre", infos.genre);
            formData.append("chapter_number", infos.chapter_number);

            formData.append("files", selectedFile);

            const response = await fetch(url,
                {
                    method: 'POST',
                    headers: {
                        // 'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    },
                    body: formData
                }
            );
            if (!response.ok) {
                throw new Error("http error! Status", response.status);
            }
            // return response.json();
        }
        catch (e) {
            console.error("Error :", e);
        }
    };
    get createBook() {
        return this._createBook;
    }
    set createBook(value) {
        this._createBook = value;
    }
    deleteBook = async (book_id) => {
        const url = `${BASE_URL}/terabook/${book_id}`;
        try {
            const response = await fetch(url,
                {
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    }
                }
            )
            if (!response.ok) {
                throw new Error("http error! Status", response.status);
            }
            // return response.json();
        }
        catch (e) {
            console.error("Error :", e);
        }
    }

}

const ApiProvider = new BookProvier();
export default ApiProvider;